/*
 * COLORS
 */

// Solid

$backgroundGrey: #F6F6F6;
$blueGrey: #828FA2;
$bodyTextGrey: #333333;
$brightPurple: #8B00F8;
$darkPurple: #4A0178;
$coolGrey: #F2F8FF;
$dark: #212124;
$darkBlue: #007DFF;
$darkGrey: #474F5A;
$grey: #7D8898;
$lightGrey: #E5E5E5;
$white: #FFFFFF;
$yellow: #FFF965;

$gray1: #1C232B;
$gray3: #7D899F;
$gray6: #F3F5F9;

$primary: $brightPurple;
$seconday: $darkBlue;
$tertiary: $yellow;

$librexBorderRadius: 10px;


// Gradients
$blueGradient: linear-gradient(180deg, #04F8FD 0%, #28AEE3 100%);
$bluePurpleFade: linear-gradient(101.41deg, #00FFFF -34.05%, #3173CC 21.61%, #453CB8 43.67%, #460F79 90.32%);
$coolBlueGradient: linear-gradient(330.34deg, #72E4FF 25.66%, #439FFF 72.47%);
$coolBlueGradientLight: linear-gradient(330.34deg, rgba(114, 228, 255, .4) 25.66%, rgba(67, 159, 255, .4) 72.47%);
$darkGradient: linear-gradient(265.8deg, #4A0178 5.7%, #200154 100.19%);
$darkBrightPurple: linear-gradient(107.01deg, #200154 -9.02%, #8B00F8 90.25%);
$pinkFade: linear-gradient(180deg, #93278F 0%, #6A2C91 100%);
$purpleFade: linear-gradient(180deg, #120148 0%, #4A0178 100%);
$yellowGradient: linear-gradient(180deg, #FFF965 0%, #ECB800 100%);;
$whiteGradient: linear-gradient(180deg, rgba(0, 92, 231, 0.02) 0%, rgba(0, 92, 231, 0) 8.57%);
