.software {
    #intro {
        padding-top: 2rem;

        p {
            font-size: 18px;
            color: $bodyTextGrey;
            line-height: 25px;
            max-width: 60vw;
        }
    }

    .software-features {
        .single-feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            flex-basis: 50%;
            margin-top: 2rem;

            p {
                margin-top: 1rem;
                text-align: center;
                font-weight: 500;
            }
        }
    }

    .integrations {
        &>div {
            flex-basis: 33%;
            max-width: 31%;
            text-align: center;
            margin-top: 1.5rem;

            img {
                width: 62px;
                margin-bottom: 2rem;
            }
        }
    }

    .see-more{
        & .indicator svg{
            margin-left: 14px;
            transform: rotate(180deg);
            transition: .2s ease-in-out;
        }

        &.expanded .indicator svg{
            transform: rotate(0deg);
        }

    }
}

@media(min-width: 768px) {
    .software {
        #intro {
            padding-top: 0px;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);

            h1 {
                color: $white;
            }

            p {
                font-size: 32px;
                color: $white;
                line-height: 48px;
            }
        }
    }
}

@media(min-width: 992px) {
    .software {

        #intro{
            h1{
                font-size: 66px;
            }
            p{
                line-height: 56px;
            }
        }
        .software-features {
            .single-feature {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        .integrations {
            &>div {
                flex-basis: 16%;
                max-width: 16%;
                margin-bottom: 48px;
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .section-lead {
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        max-width: 600px;
        position: absolute;
        bottom: 0px;
    }
}