h1,
.h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
}

h2,
.h2 {
    font-size: 24px;
    line-height: 26px;
}

h5,
.h5 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 55px;
    font-weight: 400;
    color: $blueGrey;
}

.bold {
    font-weight: 700 !important;
}

a {
    text-decoration: none;
}

.nav-link,
a.nav-link,
.btn-link.nav-link {
    font-weight: 400;
}

/*
 * TEXT COLORS
 */
.text-blue-gradient {
    background: $blueGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-cool-blue-gradient {
    background: $coolBlueGradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-dark-bright-purple-gradient {
    background: linear-gradient(107.01deg, #200154 -9.02%, #8B00F8 90.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-dark-blue {
    color: $darkBlue;
}

.text-grey{
    color: $grey;
}


.text-blue-grey {
    color: $blueGrey
}

.text-body-grey {
    color: $bodyTextGrey;
}

.text-purple {
    color: $primary !important;
}

.purple-link {
    color: $primary !important;
    text-decoration: none;
    transition: .5s ease all;

    &:hover {
        color: $primary !important;
    }

    &:hover .triangle-right-purple {
        border-left-color: $primary !important;
    }
}

.secondary-link {
    color: $seconday !important;
    text-decoration: none;
    transition: .5s ease all;

    &:hover {
        color: $primary !important;
    }

    &:hover .triangle-right-yellow {
        border-left-color: $primary !important;
    }
}

.tertiary-link {
    color: $tertiary !important;
    text-decoration: none;
    transition: .5s ease all;

    &:hover {
        color: $primary !important;
    }

    &:hover .triangle-right-yellow {
        border-left-color: $primary !important;
    }
}

a.large-link,
button.btn.large-link,
.large-link {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
}

a.xlarge-link,
.xlarge-link {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 700;
}

@media(min-width: 992px) {
    h1 {
        font-size: 36px;
        line-height: 48px;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    h1 {
        font-size: 48px;
        line-height: 56px;
    }
}