$enable-negative-margins: true;

html,
body {
    width: 100vw;
    overflow-x: hidden;
}

body {
    padding-top: 62px;
}

html,
body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.01em;
    color: $bodyTextGrey;
}

html,
body,
div,
p {
    font-weight: 300;
}

.pt {
    padding-top: 14px;
}

.py {
    padding-top: 14px;
    padding-bottom: 14px;
}

.text-thin {
    font-weight: 100;
}

.absolute-center-x {
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/*
 * Background colors
 */

.bg-white-gradient {
    background: $whiteGradient;
}

.bg-dark-purple {
    background: $darkPurple;
}

.bg-bright-purple {
    background: $primary;
}

.bg-light-grey {
    background: $lightGrey;
}

@media(min-width: 992px) {
    .pt {
        padding-top: 90px;
    }

    .py {
        padding-top: 90px;
        padding-bottom: 90px;
    }
}

.btn {
    font-size: 18px;
    padding: 16px 22px;
    &.btn-link {
        font-weight: 300;
        color: #0d6efd;
        text-decoration: none;

        &:hover {
            color: #0a58ca
        }
    }

    &.btn-outline-secondary{
        color: $seconday;
        border-color: $seconday;
        &:hover{
            background-color: $seconday;
            color: $white;
        }
    }
}