.prep-steps {
    display: flex;

    &>div {
        flex-basis: 33.33%;
        padding: 0 .5rem;
    }
}

.prep-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .img-background {
        height: 190px;
        border-radius: 20px;
    }

    &>div {
        flex-basis: 100%;
    }
}

@media(min-width: 992px) {
    .prep-features {

        .img-background {
            height: 330px;
        }

        &>div {
            flex-basis: 47.5%;
            margin-bottom: 1rem;
        }
    }
}