.slide-in-right {
    position: absolute;
    transition: .75s ease all;
    left: 50vw;
    width: 100%;
    opacity: 0%;

    &.entered {
        left: 0;
        opacity: 100%;
    }

    &.exiting {
        left: -50vw;
        opacity: 0%;
    }

    &.exited {
        opacity: 0%;
    }

}

#boxTop,
.animated-integration {
    transition: 2s ease all;
}

.animated-integration {
    z-index: -200;
    max-width: 60px;
    position: absolute;
    left: 50%;
    bottom: 0px;
}



.box-closed {

    #boxTop,
    .animated-integration {
        transform: translate(-50%, 0px);
    }
}

.box-opened {
    #boxTop {
        transform: translate(-50%, -240px);
    }

    #amazonAnimated {
        transform: translate(-100%, -220px);
    }

    #shopifyAnimated {
        transform: translate(100%, -200px);
    }

    #ebayAnimated {
        transform: translate(-200%, -170px);
    }

    #walmartAnimated {
        transform: translate(-45%, -140px);
    }

    #woocommerceAnimated {
        transform: translate(-175%, -100px);
    }

    #etsyAnimated {
        transform: translate(75%, -100px);
    }
}