.home {
    .background {
        position: relative;
        top: 0px;
        width: 100vw;
        z-index: -500;
        height: 0px;
        padding-top: calc(748/511*100%);

        img {
            width: 100vw;
            height: auto;
            position: absolute;
            top: 0px;
            left: 0px;
        }
    }

    .make-it-simple {
        background: rgba(180, 216, 255, 0.3);
    }

    #make-it-simple {
        display: flex;
        justify-content: space-between;
        min-height: 185px;
        height: 185px;
        width: 100%;

        #carousel {
            position: relative;
            height: 100%;
            width: 100%;

            .part {
                font-weight: 700;
                font-size: 30px;
                margin-bottom: 0px;
                position: absolute;
                opacity: 0;
                transition: 0.5s ease-in-out all;

                &.exit-up {
                    opacity: 0;
                    top: -50px;
                }

                &.exit-down {
                    opacity: 0;
                    top: 50px;
                }

                &.active {
                    opacity: 1;
                    top: 12px;
                    left: 0px
                }
            }
        }

        #slideBarContainer {
            max-height: 180px;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            position: relative;

            #slideBar {
                position: relative;
                min-width: 10px;
                border-radius: 13px;
                background: linear-gradient(270.13deg, #72E4FF 19.75%, #439FFF 74.74%);
                margin-right: -1.5rem;
                z-index: 0;

                #sliderKnob {
                    min-width: 18px;
                    min-height: 18px;
                    background: $primary;
                    border-radius: 50%;
                    position: absolute;
                    left: -4.5px;
                    transition: 0.5s ease-in-out all;
                    z-index: 100;
                }
            }

            .slider {
                padding-left: 2.5rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                z-index: 50;
            }

        }
    }

    .steps {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .step {
            display: flex;
            flex-direction: column;
        }

        img {
            max-width: 70vw;
            max-height: 143px;

            &.ship {
                margin-left: 30px;
            }
        }
    }

    .inquire-now {
        .card-img-top {
            width: 100%;
            max-width: 180px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .integrations {
        &>div {
            flex-basis: 25%;
            max-width: 25%;
            text-align: center;
            margin-top: 1.5rem;

            img {
                width: 62px;
            }
        }
    }

    .trustpilot {
        display: flex;
        position: relative;

        & .background {
            position: absolute;
            flex-grow: 1;
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 100%;
            overflow: hidden;
        }

        .trustpilot-review {
            max-width: 574px;
            max-height: 325px;
            border: 0px solid transparent;
            border-radius: 24px;
            margin-bottom: 20px;

            & .card-body p {
                max-height: 155px;
                overflow-y: auto;
            }

            & .name {
                font-size: 14px;
            }

            & .location {
                font-size: 12px;
            }

            & .rating {
                width: 108px;
                height: 20px;
            }
        }
    }

    .community {
        position: relative;
        background-color: $white;

        .h1 {
            font-size: 36px;
        }
    }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .home {
        .home-info {
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
        }

        .make-it-simple {
            background: transparent;
        }

        #make-it-simple {
            position: absolute;
            max-width: 350px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .home {
        .background{
            padding-top: calc(1248/1920*100%);
        }

        #make-it-simple {
            max-width: 450px;

            p {
                font-size: 18px;
            }

            #carousel {
                .part {
                    font-size: 36px;
                }
            }
        }

        .steps {
            flex-direction: row;
            align-items: flex-end;

            .svg-1 {
                left: -30px;
            }

            .step {
                flex-grow: 1;
            }

            img {
                max-height: 100%;

                &.ship {
                    margin-left: 0px;
                }
            }
        }

        .integrations {
            &>div {
                margin-bottom: 48px;

                img {
                    max-width: 100px;
                    margin-bottom: 1rem;
                }
            }
        }

        .trustpilot {
            .trustpilot-review {
                max-width: unset;
                flex-basis: 49%;

                &:nth-child(odd) {
                    margin-right: 20px;
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .home {
        #make-it-simple {
            width: 520px;
        }

        .steps {
            .svg-1 {
                left: -55px;
            }
        }
    }
}

@media (max-width: 491.8px){
    .home .integrations > div {
        flex-basis: 33%;
        max-width: 33%;
    }
}

@media (min-width: 492px) {
    .home {
        .hanger {
            display: none;
        }
    }
}