.contact {
    max-width: 550px;
    padding: 15px;
    z-index: 100;

    form {

        h1,
        p {
            word-wrap: break-word;
        }

        .row {
            max-width: 400px;
        }

        label {
            text-transform: uppercase;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            color: $blueGrey;
        }

        input,
        textarea {
            background-color: $coolGrey;
            border-radius: 9px;
            margin-bottom: 12px;
        }

        input{
            height: 48px;
        }

        button[type=submit] {
            background-color: transparent;
            border: 0px solid transparent;

            margin-top: 36px;
        }
    }
}

@media(min-width: 768px) {
    .contact {
        border-radius: 34px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        padding: 65px;
    }
}