nav {
    height: 62px;
    z-index: 500;

    .navbar-toggler:focus{
        box-shadow: none;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        color: $dark;
    }

    #mainNav {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        background: linear-gradient(298.56deg, rgba(0, 255, 255, 0.98) -15.92%, rgba(49, 115, 204, 0.98) 27.89%, rgba(69, 60, 184, 0.98) 61.93%, rgba(70, 15, 121, 0.98) 109.25%);
        border-radius: 18px;
        position: absolute;
        top: 12px;
        width: 90vw;
        max-width: 400px;
        right: 1rem;
        z-index: 510;

        &.show+#overlay {
            display: block;
        }

        .navbar-nav {
            margin-right: 40px;

            &.d-xl-none{
                margin-right: 0px;
            }


            .nav-link {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                color: $white;

                &:hover {
                    color: $primary;
                }

                &.active{
                    color: $tertiary;
                }
            }

        }

        a {
            &>svg path {
                fill: #FFFFFF;

            }
        }
    }

    #overlay {
        display: none;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 400;
        overflow: hidden;
    }
}

// Extra large devices (desktops, 1200px and up)
@media (min-width: 1200px) {
    nav {

        #mainNav {
            box-shadow: none;
            background: transparent;
            width: unset;
            max-width: unset;
            position: relative;
            top: 0px;
            right: 0px;


            &.show+#overlay {
                display: none;
            }

            .navbar-nav {
                .nav-item {
                    padding: 0px 15px;
                    .nav{
                        height: 100%;
                    }

                    .nav-link {
                        color: $dark;
                        font-weight: 700;

                        &:hover {
                            color: $primary;
                        }

                        &.active {
                            color: $dark;
                            border-bottom: 2px solid $dark;
                            padding-bottom: 0px;
                        }
                    }
                }
            }

            a {
                &>svg path {
                    fill: $dark;

                }
            }
        }


        #overlay {
            display: none;
        }
    }
}