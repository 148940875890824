// HTML based Components 
.img-background{
    background-color: $backgroundGrey;
}
.features-we-offer {

    .col-12.bg-white-gradient {
        padding-top: 100px;
    }

    .sections-link {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $blueGrey;
        text-align: right;

        &.active {
            color: $primary;
            font-size: 1.5rem;
            line-height: 1.75rem;
        }

        &:hover {
            cursor: pointer;
        }
    }

    #activeSection {
        font-size: 28px;
    }

    #sectionCarousel {
        
        .carousel-indicators {
            position: static;
            margin-left: 0px;
            margin-right: 0px;
            justify-content: flex-start;

            & li{
                width: 8px;
                height: 8px;
                background-color: #CFDCEA;
                border-radius: 50%;

                &.active{
                    background-color: $primary;
                }
            }
        }
    }

    .feature-list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
        }
    }
}

@media(min-width: 375px){
    .features-we-offer {
        #activeSection {
            font-size: 30px;
        }
    }
}

@media(min-width: 992px) {

    #make-it-simple {
        min-height: 185px;
        height: 185px;
        min-width: 430px;
        position: absolute;

        .part {
            font-size: 36px;
        }

        #slideBar {
            min-width: 10px;
            min-height: 170px;
            border-radius: 13px;
            background: linear-gradient(270.13deg, #72E4FF 19.75%, #439FFF 74.74%);
        }
    }

    .features-we-offer{
        .feature{
            & > div{
                height: 400px;
            }
        }
        #activeSection{
            font-size: 36px;
        }
    }
}

// CSS based components
.divider {
    border: 0.5px solid rgba(255, 255, 255, 0.4);
}

.vertical-divider {
    border: 1px solid rgba(0, 92, 231, 0.0875);
    width: 2px;
    height: 100%;
}

.circle {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 50%;
    line-height: 32px;
    text-align: center;
    display: block;
    color: $white;
    font-weight: 700;

    &.circle-medium {
        width: 48px;
        min-width: 48px;
        height: 48px;
        min-height: 48px;
        line-height: 48px;
        font-size: 32px;
    }

    &.circle-large {
        width: 60px;
        min-width: 60px;
        height: 60px;
        min-height: 60px;
        line-height: 60px;
        font-size: 36px;
    }
}

.triangle-right-yellow {
    width: 0;
    height: 0;
    font-size: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    border-top: 10px solid transparent;
    border-left: 10px solid $seconday;
    border-bottom: 10px solid transparent;
    transition: .5s ease all;
}

.triangle-right-purple {
    width: 0;
    height: 0;
    font-size: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    border-top: 10px solid transparent;
    border-left: 10px solid $primary;
    border-bottom: 10px solid transparent;
    transition: .5s ease all;
}