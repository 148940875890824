.about {
    #intro {
        p {
            max-width: 350px;
        }
    }

    .timeline {
        img {
            width: 100%;
            height: auto;
            max-width: 250px;
            max-height: 250px;
        }

        div.d-inline-flex {
            justify-content: space-between;

            &>div {
                flex-basis: 45%;
            }
        }
    }
}

@media(min-width: 992px) {
    .about {
        .timeline {
            p.year-description {
                max-width: 170px;
            }

            div.d-inline-flex {
                justify-content: space-between;

                &>div {
                    flex-basis: unset;
                }
            }


            #cubes {
                max-width: 50%;
                height: auto;
            }

            #livingRoom {
                max-width: 15vw;
                height: auto;
                max-height: 110px;
            }

            #inc {
                img {
                    max-width: 12vw;
                    height: auto;
                    max-height: 99px;
                }
            }

            #smallWarehouse {
                max-width: 11vw;
                height: auto;
                max-height: 195px;
            }

            #multiWarehouse {
                max-width: 20.5vw;
                height: auto;
                max-height: 185.44px;
            }

            #software {
                max-width: 23vw;
                height: auto;
                max-height: 215px;
            }

            #bigWarehouse {
                max-width: 36vw;
                height: auto;
                max-height: 358px;
                margin-right: -8vw;
            }
        }
    }
}