.get-started {
    #whatsIncluded {
        .include-list {
            max-height: 675px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            max-width: 500px;
        }

        .single-included {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            max-width: 262px;
            flex-basis: 50%;
            margin-bottom: 1.5rem;

            p {
                max-width: 200px;
                word-wrap: break-word;
                word-break: break-word;
                margin-bottom: 0;
                text-align: center;
            }
        }

        h1 {
            color: $bodyTextGrey;
        }

        .hassle-free {
            display: flex;
            align-items: center;

            .card {
                height: 180px;
                justify-content: center;
                flex-basis: 50%;

                img {
                    height: 100px
                }
            }
        }
    }

    .faq {
        display: flex;
        flex-direction: column;

        .question {
            flex-basis: 100%;
        }
    }
}

@media(min-width: 992px) {
    .get-started {
        #whatsIncluded {
            .include-list {
                max-height: 550px;
            }

            .single-included {
                flex-direction: row;
                justify-content: flex-start;

                img {
                    margin-right: 12px;
                }

                p {
                    text-align: left;
                }
            }
        }

        .faq {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .question {
                flex-basis: 47.5%;
                margin-top: 1rem;
                margin-bottom: auto;
                border-bottom-width: 1px;

                & .accordion-button.collapsed{
                    border-bottom-width: 1px;
                }

                & .accordion-collapse{
                    border-bottom-width: 1px;
                }
            }
        }
    }
}