@import "./variables";

.onboarding-contact-form {
    z-index: 1000;
    border-radius: 20px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    background-color: $white;

    .half-1, .half-2{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .onboarding-contact-form-section-break{
        display: flex;
        align-items: center;
        margin: 2rem 0px;
        .onboarding-contact-form-divider{
            flex: auto;
            height: 1px;
            background-color: $gray3;
            &:first-child{
                margin-right: 1rem;
            }
            &:last-child{
                margin-left: 1rem;
            }
        }
        color: $gray3;
    }

    input.form-control{
        background-color: $gray6;
        border-radius: $librexBorderRadius;
        border: 0px solid transparent;
        color: $gray1;

        &::placeholder{
            color: $gray3;
        }
    }

    .contact-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>.btn {
            margin-bottom: 1rem;
        }
    }
}

@media(min-width: 768px) {
    .onboarding-contact-form {
        &:not(.always-small){
            .onboarding-contact-form-section-break{
                flex-direction: column;
                margin: 0px;
            }
            
            .onboarding-contact-form-divider{
                width: 1px;
                height: auto;
                background-color: $gray3;
                &:first-child{
                    margin-right: 0px;
                    margin-bottom: 1rem;
                }
                &:last-child{
                    margin-left: 0px;
                    margin-top: 1rem;
                }
            }

            .half-1, .half-2{
                min-width: 45%;
                max-width: 45%;
            }
        }

        .contact-buttons:not(.always_small) {
            flex-direction: row;

            &>.btn {
                flex-basis: 47.5%;
                margin-bottom: 0rem;
            }

        }

    }
}