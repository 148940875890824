
@import "./variables";
@import "bootstrap";
@import "./overrides";
@import "./text";
@import "./animations";

@import "./components";
@import "./nav";
@import "./footer";
@import "./home";
@import "./software";
@import "./getStarted";
@import "./services";
@import "./about";
@import "./contact";


.logo{
    height: 24px;
    width: auto;
}