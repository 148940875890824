.services{
    .overview{
        &>div{
            max-width: 300px;
            p{
                word-wrap: break-word;
            }
        }
    }

    .how-it-happens{
        .d-inline-flex{
            flex-basis: 30%;
        }
    }
    .section{
        h2{
            font-weight: 700;
            margin-bottom: 1rem;
        }
        p{
            max-width: 350px;
        }
    }
}

@media(min-width: 992px){
    .services{
        .how-it-happens{
            .d-inline-flex{
                flex-basis: 100%;
            }
        }
    }
}