footer {
    margin-top: auto;
    min-height: 500px;
    background: $darkGradient;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: $white;

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .icon{
        max-width: 32px;
        color: $white;
    }

    .icon-small {
        width: auto;
        max-width: 16px;
        height: auto;
        max-height: 16px;
    }

    .nav-link, .btn-link.nav-link {
        color: $white;
        transition: cubic-bezier(0.19, 1, 0.22, 1);

        &:hover {
            color: $seconday;
            cursor: pointer;
        }
    }

    .divider {
        width: 16rem;
    }
}